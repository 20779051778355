<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import Container from '../atoms/Container.svelte'
    import Heading from '../atoms/Heading.svelte'
    import { concatClasses } from '../utils'

    interface Props extends HTMLAttributes<HTMLElement> {
        headingClass?: string
        size?: 'sm' | ''
        preheading?: Snippet
        heading: Snippet
        content?: Snippet
    }

    const {
        class: classNames = '',
        headingClass = '',
        size = '',
        preheading,
        heading,
        content,
        ...rest
    }: Props = $props()

    const classes = $derived(
        concatClasses(['hero', size ? `hero-${size}` : '', classNames])
    )
</script>

<header class={classes} {...rest}>
    <Container>
        {#if preheading}
            <Heading class="mb-3" level="6" useDiv>
                {@render preheading()}
            </Heading>
        {/if}
        <Heading
            class="hero-heading {headingClass}"
            decoration
            level="1"
            size="1"
        >
            {@render heading()}
        </Heading>

        {#if content}
            {@render content()}
        {/if}
    </Container>
</header>

<style global lang="postcss">
    .hero {
        padding-top: theme('spacing.12');
        padding-bottom: theme('spacing.12');
        color: theme('colors.gray-600');
        background-color: theme('colors.white');

        @media screen(sm) {
            padding-top: theme('spacing.16');
            padding-bottom: theme('spacing.16');
        }

        @media screen(md) {
            padding-top: theme('spacing.20');
        }
    }

    .hero.hero-sm {
        padding-top: theme('spacing.12');
        padding-bottom: 0;
    }

    .hero-heading {
        margin-bottom: theme('spacing.6');
        line-height: 1.2;
        color: var(--primary-600, theme('colors.gray-600'));

        @media screen(sm) {
            margin-bottom: theme('spacing.8');
        }

        span {
            color: var(--primary-500, theme('colors.gray-500'));
        }
    }

    .page-frame .play-button svg {
        width: clamp(3rem, 2.545rem + 7.27vw, 6rem);
    }
</style>
